import React from 'react';
import * as styles from './linealprocess.module.scss';



const LinealProcessSection = () => {
	return (
		<section className={styles.processSection}>
            <div className={styles.containProcess}>
                <div className={styles.containTitle} 
                    data-sal-delay='500'
             		data-sal='zoom-in'
            		data-sal-duration='400'
            		data-sal-easing='ease-out-back'>
                    <h2>Proceso de trabajo</h2>
                    <p>El objetivo de este proceso de trabajo es hacer ágil y fácil para nuestros clientes la construcción de una solución de calidad.</p>
                </div>
                <div className={styles.containBody}>
                    <div className={styles.step}
                        data-sal-delay='200'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>1</p>
                            </div>
                            <div className={styles.title}>
                                <h3>Hora del análisis</h3> 
                            </div>
                            <div className={styles.description}>
                                <p>El equipo técnico y funcional se asegura de diseñar la solución perfecta.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.step}                        
                        data-sal-delay='400'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>2</p>
                            </div>
                            <div className={styles.title}>
                                <h3>Entrega de documentos</h3> 
                            </div>
                            <div className={styles.description}>
                                <p>Todo el análisis realizado en un entregable fácil de leer, controlar y aprobar.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.step}                        
                        data-sal-delay='600'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>3</p>
                            </div>
                            <div className={styles.title}>
                                <h3>Desarrollo de la solución</h3> 
                            </div>
                            <div className={styles.description}>
                                <p>El equipo de desarrollo, calidad y gestión crean la solución.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.step}                        
                        data-sal-delay='800'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>4</p>
                            </div>
                            <div className={styles.title}>
                                <h3>Entregas parciales</h3> 
                            </div>
                            <div className={styles.description}>
                                <p>Cada entregable podrás probarlo para asegurarte que funciona como lo esperabas.</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.step}                        
                        data-sal-delay='1000'
                        data-sal='slide-right'
                        data-sal-duration='500'
                        data-sal-easing='ease-out-back'>
                        <div className={styles.detalle}>
                            <div className={styles.number}>
                                <p>5</p>
                            </div>
                            <div className={styles.title}>
                                <h3>Implementación del proyecto</h3> 
                            </div>
                            <div className={styles.description}>
                                <p>Se realiza la entrega final y la solución queda lista para usarse.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
		</section>
	);
};
export default LinealProcessSection;
