import React from 'react';
import * as styles from './values.module.scss';
import compromiso from '../../../images/Home/compromiso.png';
import ico1 from '../../../images/Home/ico1.png';
import ico2 from '../../../images/Home/ico2.png';
import ico3 from '../../../images/Home/ico3.png';
import facial from '../../../images/Home/facial.png';


const ValuesSection = () => {
	return (
		<section className={styles.valuesSection}>
            <div className={styles.containSection}>
                <div className={styles.centerContain}>
                    <div className={styles.containTitle}
                        data-sal-delay='500'
                        data-sal='zoom-in'
                        data-sal-duration='700'
                        data-sal-easing='ease-out-back'>
                        <h2>Pilares de nuestro servicio</h2>
                        <p>Para lograr nuestro propósito creamos una metodología basada en los siguientes pilares:</p>
                    </div>
                    <div className={styles.containBody}>
                        <div className={styles.containLeft}>
                            <div 
                                data-sal-delay='400'
                                data-sal='slide-right'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>
                                <img src={ico2} alt='imagen de referencia' />
                                <h3>Entregas a tiempo</h3>
                                <p>Nuestra promesa es un compromiso asumido.</p>
                            </div>
                            <div
                                data-sal-delay='400'
                                data-sal='slide-right'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>
                                <img src={ico3} alt='imagen de referencia' />
                                <h3>Calidad</h3>
                                <p>Contamos con procesos de calidad y revisión de código.</p>
                            </div>
                            <div
                                data-sal-delay='400'
                                data-sal='slide-left'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>                           
                                <img src={compromiso} alt='imagen de referencia' />
                                <h3>Propiedad intelectual</h3>
                                <p>Entregamos el código completo.</p>
                            </div>
                        </div>
                        <div className={styles.containRight}>
                             <div 
                                data-sal-delay='400'
                                data-sal='slide-left'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>
                                <img src={ico1} alt='imagen de referencia' />
                                <h3>Transparencia</h3>
                                <p>Cada cliente ve en tiempo real el avance del proyecto.</p>
                            </div>
                            <div
                                data-sal-delay='400'
                                data-sal='slide-left'
                                data-sal-duration='700'
                                data-sal-easing='ease-out-back'>                           
                                <img src={facial} alt='imagen de referencia' />
                                <h3>Accesibilidad</h3>
                                <p>Cada cliente tiene la posibilidad de resolver sus inquietudes con el equipo.</p>
                            </div>
                        </div>
                    </div>          
                </div>            
            </div>
		</section>
	);
};
export default ValuesSection;
