import React from 'react';
import * as styles from './quality.module.scss';


const QualitySection = () => {
	return (
		<section className={styles.qualitySection}>
            <div className={styles.containQuality}>
                <div className={styles.containTitle}
                    data-sal-delay='300'
                    data-sal='zoom-in'
                    data-sal-duration='700'
                    data-sal-easing='ease-out-back'>
                    <h4>Aseguramiento de la calidad.</h4>
                    <p>Todo el código creado en Codeicus es verificado mediante procesos manuales y automáticos con el
                        fin de asegurar que el mismo cumpla con las especificaciones de calidad y funcionales.</p>
                </div>
            </div>     
        </section>
	);
};
export default QualitySection;