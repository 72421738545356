import React from 'react';
import * as styles from './improvements.module.scss';


const ImprovementsSection = () => {
	return (
		<section className={styles.improvementsSection}>
            <div className={styles.containSection}>
                <div className={styles.centerContain}>
                    <div className={styles.containLinks}>
                        <div className={styles.containLinksTitle} 
                            data-sal-delay='500'
                            data-sal='zoom-in'
                            data-sal-duration='700'
                            data-sal-easing='ease-out-back'>
                            <h2>¿Cómo participan <br/> nuestros clientes?</h2>      
                            <p>Nuestro proceso de trabajo está en permanente cambio.</p>
                            <p>Cada una de las mejoras implementadas están basadas en las devoluciones que recibimos
                                de nuestros clientes sobre su experiencia de trabajo con nosotros.</p>
                            <p>Podes conocer todas las mejoras que implementamos:</p>
                        </div>
                        <div className={styles.containLinksDescription}>
                                <div
                                    data-sal-delay='400'
                                    data-sal='slide-up'
                                    data-sal-duration='700'
                                    data-sal-easing='ease-out-back'> 
                                    <a href='#TODO' target="_blank" rel="noopener noreferrer">
                                        <p>VER MEJORAS</p>
                                    </a>
                                </div>
                        </div>
                    </div>               
                </div>            
            </div>
		</section>
	);
};
export default ImprovementsSection;
