import React from "react";
import Layout from "../components/UI/layout";
import Header from "../components/UI/Header/header";
import SEO from "../components/UI/seo";
import LinealProcessSection from "../components/LandingCX/LinealProcess/linealprocess";
import Values from "../components/LandingCX/Values/values";
import Quality from "../components/LandingCX/Quality/quality";
import ImprovementsSection from "../components/LandingCX/Improvements/improvements";


const IndexPage = () => (
    <Layout>
      <SEO title="Codeicus | Landing CX" />
      <Header
        title="Nuestro propósito"
        description={["En Codeicus tenemos un propósito y es solucionar problemas utilizando <strong>tecnología</strong> priorizando la <strong>innovación</strong>, la <strong>calidad</strong> y las <strong>personas</strong>."]}
      />
      <Values/>
      <LinealProcessSection/>
      <Quality/>
      <ImprovementsSection/>
    </Layout>
  )
  
  export default IndexPage